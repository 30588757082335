export const translationEN = {
  appTitle: "Konsti",
  appDescription: "Konsti – {{EVENT_NAME}} {{EVENT_YEAR}}",
  announcement: "Sign-up has ended. Results will be released soon.",
  button: {
    logout: "Logout",
    login: "Login",
    register: "Create account",
    updateDb: "Update program database",
    assignAttendees: "Assign attendees",
    signup: "Save",
    cancelSignup: "Cancel sign-up",
    showAdmissionTicket: "Show admission ticket",
    showInfo: "Show info",
    back: "Back",
    favorite: "Favorite",
    unfavorite: "Remove from favorites",
    hideProgramItem: "Hide program item",
    showProgramItem: "Unhide program item",
    saveTime: "Save active time",
    sendFeedback: "Send feedback",
    feedbackSent: "Feedback sent",
    createGroup: "Create group",
    joinGroup: "Join group",
    createGroupConfirmation: "Create",
    leaveGroup: "Leave group",
    closeGroup: "Close group",
    closeApp: "Close Konsti",
    openApp: "Open Konsti",
    cancel: "Cancel",
    helper: "Helper",
    find: "Find",
    save: "Save",
    addSignupQuestion: "Add sign-up question",
    removeSignupQuestion: "Delete sign-up question",
    close: "Close",
    scrollToTop: "Scroll to top",
  },
  username: "Username",
  password: "Password",
  serial: "Registration code",
  code: "Code",
  loading: "Loading...",
  hours: "hours",
  minutes: "minutes",
  show: "Show",
  hide: "Hide",
  sortBy: "Sort by",
  find: "Find",
  findTitleOrUsername: "{{PROGRAM_TYPE}} title or username",
  searchWithTitle: "{{PROGRAM_TYPE}} title",
  searchWithTitleOrSystem: "{{PROGRAM_TYPE}} title or system",
  hideFullItems: "Hide fully booked program items",
  language: {
    english: "English",
    englishShort: "EN",
    finnish: "Finnish",
    finnishShort: "FI",
  },
  validation: {
    required: "Required",
    tooLong: "Too long, max {{length}} characters",
    tooShort: "Too short, at least {{length}} characters required",
  },
  pages: {
    myProgram: "My Program",
    programList: "All Program",
    program: "Program",
    admin: "Admin",
    group: "Group",
    notifications: "Notifications",
    profileAndGroup: "Profile & Group",
    profile: "Profile",
    help: "Help",
  },
  programItemInfo: {
    playstyle: "Playstyle",
    runTime: "Time",
    description: "Description",
    location: "Location",
    duration: "length",
    tags: "Tags",
    time: "Starting time",
    gameSystem: "Game system",
    genres: "Genres",
    organiser: "Organiser",
    shortDescription: "Short description",
    revolvingDoor:
      "This is a revolving door {{PROGRAM_TYPE}}. You can join it at any time if there is free space.",
    programType: "Program type",
    showMore: "Show more",
    showMoreAriaLabel: "Show more of the program item",
    showLess: "Show less",
    showLessAriaLabel: "Show less of the program item",
    contentWarnings: "Content warnings",
    otherAuthor: "Designer",
    accessibility: "Accessibility",
    otherAccessibilityInformation: "Other accessibility information",
    adminActions: "Admin actions",
  },
  playstyle: {
    serious: "Serious",
    light: "Light",
    rulesHeavy: "Rules heavy",
    rulesLight: "Rules light",
    storyDriven: "Story driven",
    characterDriven: "Character driven",
    combatDriven: "Combat driven",
  },
  genre: {
    fantasy: "Fantasy",
    scifi: "Sci-fi",
    historical: "Historical",
    modern: "Modern",
    war: "War",
    horror: "Horror",
    exploration: "Exploration",
    mystery: "Mystery",
    drama: "Drama",
    humor: "Humor",
    adventure: "Adventure",
  },
  programItemLanguage: {
    english: "In English",
    finnish: "In Finnish",
    swedish: "In Swedish",
    finnishOrEnglish: "In Finnish or in English",
    languageFree: "Language free",
  },
  tags: {
    childrenFriendly: "Children friendly",
    beginnerFriendly: "Beginner friendly",
    guestOfHonor: "Guest of Honor",
    aimedAtChildrenUnder13: "Aimed at children under 13",
    aimedAtChildrenBetween13to17: "Aimed at children between 13-17",
    aimedAtAdultAttendees: "Aimed at adult attendees",
    for18PlusOnly: "For 18+ only",
    forExperiencedParticipants: "Experience with game system",
    allAges: "All ages",
    themeMonsters: "Theme: Monsters",
  },
  pageTitle: {
    login: "Please login",
    registration: "Create user account",
  },
  user: "User",
  favoriteProgramItems: "Favorites",
  lotterySignups: "Lottery sign-ups",
  directSignups: "Sign-ups",
  hiddenProgramItems: "Hidden program items",
  error: {
    usernameTaken: "Username already taken",
    invalidSerial: "Invalid registration code",
    loginFailed: "Username and password don't match",
    loginDisabled: "Login is disabled",
    invalidUserGroup: "Invalid user group",
    unknown: "Unknown error",
  },
  agreePrivacyPolicy: "I agree to the privacy policy",
  showPrivacyPolicy: "Show privacy policy",
  hidePrivacyPolicy: "Hide privacy policy",
  registrationSerialHelp:
    "You can get a registration code from the Larp & RPG Desk, the Gaming Desk or the Info.",
  noLotterySignups:
    "No selected program items. You can sign up in the All Program view.",
  noFavoriteProgramItems:
    "No favorites. You can add program items here in the All Program view.",
  noDirectSignups:
    "No sign-ups. You can sign up to program in the All Program view.",
  noHiddenProgramItems: "No hidden program items",
  noSignupQuestions: "No sign-up questions",
  signupQuestions: "Sign-up questions",
  noOpenSignups: "No open sign-ups",
  signupOpen: "Sign-up open for the following times",
  lotterySignupOpenBetween: "lottery sign-up open",
  signupSaved: "Sign-up saved",
  signupError: {
    generic: "Sign-up failed",
    cancelFailed: "Error canceling sign-up",
    signupEnded: "Sign-up ended",
    samePriority: "Priority already selected",
    signupNotOpenYet: "Sign-up not open yet",
    programItemFull: "This program item is full.",
    noKonstiSignup:
      "This program does not use Konsti for signing up. See the program details for instructions.",
  },
  signupUnsavedChanges: "Unsaved changes",
  closingMessage: "Konsti is closed and will open closer to the next event",
  details: "details",
  noLotterySignupResult:
    "Unfortunately, you didn't get a spot in any program item in the lottery.",
  invalidProgramItemId:
    "Program item {{PROGRAM_ITEM_ID}} not found. Either program item does not use Konsti for signups or the identifier is incorrect.",
  feedbackTitle: "Feedback",
  feedbackInstruction:
    "Did you enjoy the {{PROGRAM_TYPE}}? Send feedback to the organizer! They will receive it after the event.",
  noProgramItemsAvailable:
    "No {{PROGRAM_TYPE}} found, please check your search conditions.",
  noProgramItemsInDatabase: "No program items in database",
  upcoming: "Upcoming",
  all: "All",
  allProgramItems: "All {{PROGRAM_TYPE}}",
  lastStartedAndUpcoming: "Last started and upcoming",
  chooseTag: "Tag",
  startingTime: "Starting time",
  revolvingDoor: "Revolving door",
  revolvingDoorInstruction:
    "You can join a revolving door {{PROGRAM_TYPE}} at any time if there is free space.",
  currentlyRunningRevolvingDoor:
    "Currently running revolving door {{PROGRAM_TYPE}}",
  noRunningRevolvingDoorProgramItems:
    "No running revolving door {{PROGRAM_TYPE}}",
  group: {
    error: {
      cannotUseOwnSerial: "You cannot use your own serial to join a group.",
      groupExists: "Group already exists.",
      removePreviousLotterySignupsFailed:
        "Error deleting previous lottery sign-ups, try again.",
      onlyCreatorCanCloseGroup: "Only the group creator can close a group.",
      userHasDirectSignups:
        "You already have sign-ups. You will have to cancel those before joining a group.",
      creatorHasDirectSignups:
        "You already have sign-ups. You will have to cancel those before creating a group.",
      alreadyInGroup: "You are already a member of a group.",
    },
    hasDirectlySignedFollowingProgramItems:
      "You have already signed up to these program items:",
    cancelSignupBeforeJoiningOrCreatingGroup:
      "You'll have to cancel these sign-ups before you can create or join a group.",
    signupDisabledNotCreator:
      "You can't sign up because you are a member of a group. Only the group creator can sign the group up for a lottery.",
    groupCreatorSignups:
      "You are the group creator. Your lottery sign-ups are the same for all group members.",
    inGroupSignups:
      "You are in a group. Sign-ups in this list have been made by your group creator.",
    closeGroupConfirmation:
      "Are you sure you want to close the group and remove all its members?",
    generalLeaveGroupError: "Error leaving group",
    groupTooBigWarning:
      "The group is bigger than the maximum number of attendees",
    enterGroupMemberCode: "User's code",
    enterGroupCreatorCode: "Group creator's code",
    createGroupConfirmationMessage: "Do you want to create a group?",
    youAreGroupCreator: "You are the group creator",
    youAreInGroup: "You are in a group",
    groupMembers: "Group members",
    groupCreator: "group creator",
    groupMemberInfo:
      'The group creator will sign the group up to the lottery. You can see the selected program items on the "My Program" page.',
    groupCreatorInfo:
      "You can sign up your group to the lottery. Please make sure that the group is not too big for the selected program items. The group creator cannot leave the group, but can close it. This will remove all members from the group.",
    groupCodeInfo: "Others can join this group with the code ",
    joiningGroupWillCancelLotterySignups:
      "Joining a group will cancel all your previous lottery signups.",
    groupLotterySignupGuide: "You can enter a lottery sign-up as a group.",
    groupLotterySignupProgramTypes: "Lottery sign-up is used by",
    invalidGroupCode: "Invalid code",
    groupNotExist: "Group does not exist",
    generalGroupError: "Error updating group",
    generalJoinGroupError: "Error joining group",
  },
  signupResults: "Sign-up results",
  helperResults: "Results",
  passwordManagement: {
    helperPasswordManagement: "Change password",
    userCode: "User code",
    userCodeOrUsername: "User code or username",
    changePassword: "Change password",
    newPassword: "New password",
    userNotFound: "User not found",
    foundUser: "Found user",
    changingPasswordError: "Error changing password.",
    changingPasswordSuccess: "Password changed succesfully.",
    userCreatedAt: "User account created",
  },
  eventLog: {
    title: "Notifications",
    noNotifications: "No notifications",
    programItemDetails: "Starting time {{START_TIME}} at {{LOCATION}}",
  },
  eventLogActions: {
    newAssignment: "You were assigned to the {{PROGRAM_TYPE}}",
    newAssignmentProgramItemMissing:
      "You were assigned to program item {{PROGRAM_ITEM_ID}} but it was canceled.",
    noAssignment:
      "Spots for program items at {{START_TIME}} were randomized. Unfortunately, we couldn’t fit you into any of your chosen program items.",
  },
  notificationBar: {
    showAll: "Show all notifications",
  },
  signup: {
    alreadyLotterySigned:
      "This {{PROGRAM_TYPE}} is priority {{CURRENT_PRIORITY}} on your lottery signups.",
    directSignup: "Sign up",
    lotterySignup: "Lottery sign-up",
    cannotLotterySignupMoreProgramItems:
      "You can select up to three lottery signups for the time slot.",
    programItemPriority: "{{PROGRAM_TYPE}} priority for me is",
    confirm: "Confirm",
    cancel: "Cancel",
    alreadySignedToProgramItem: "You already signed up to the {{PROGRAM_TYPE}}",
    cannotSignupMoreThanOneProgramItem:
      "You cannot sign up to another program item starting at the same time.",
    signupWillBeRemoved:
      "If you get a spot to this {{PROGRAM_TYPE_THIS}} in the lottery, your sign-up to the {{PROGRAM_TYPE_OTHER}} {{OTHER_PROGRAM_NAME}} will be canceled.",
    signupCount: "{{ATTENDEE_COUNT}}/{{MAX_ATTENDANCE}} sign-ups",
    attendeesNeeded: "{{COUNT}} more {{ATTENDEE_TYPE}} needed",
    programItemFull: "This {{PROGRAM_TYPE}} is full.",
    confirmCancellation: "Cancel your sign-up",
    staySignedUp: "Stay signed up",
    expectedDuration: "Duration {{EXPECTED_DURATION}}",
    directSignupOpenNow: "Sign-up is open",
    loginToSignup: "Log in to sign up",
    signupAlwaysOpen: "Sign-up always open",
    inGroupWarning:
      "If you sign up to a {{PROGRAM_TYPE}}, you will be removed from your current group. (Groups can only be used in lottery signups.)",
    groupCreatorWarning:
      "If you sign up to a {{PROGRAM_TYPE}}, the group you created will be deleted. Groups can only be used in lottery signups.",
    groupSignupInfo: "Please note that you are signing up your whole group.",
    currentSignup: "You have signed up to this {{PROGRAM_TYPE}}.",
    entryFee: "Material fee {{ENTRY_FEE}}",
    minAttendanceMissing: "Missing info: minimum number of {{ATTENDEE_TYPE}}",
    maxAttendanceMissing: "Missing info: maximum number of {{ATTENDEE_TYPE}}",
    showAttendees: "Show {{ATTENDEE_TYPE}}",
    hideAttendees: "Hide {{ATTENDEE_TYPE}}",
    noAttendees: "No {{ATTENDEE_TYPE}}",
    loginLink: "Log in",
    loginLinkEnding: " to see {{ATTENDEE_TYPE}}.",
    signupQuestionAriaLabel: "Sign-up question",
    help: {
      doesNotRequireSignup:
        "This {{PROGRAM_TYPE}} does not require signing up.",
      noKonstiSignup:
        "This {{PROGRAM_TYPE}} does not use Konsti for signing up. See the program details for instructions.",
      signupAlwaysOpen:
        " You can sign up to this {{PROGRAM_TYPE}} at any time.",
      signupAlwaysOpenGroupMemberInfo:
        "You can sign up even though you are a group member.",
      directSignupStartsLater:
        "Participants are selected in the order of sign-ups. Sign-up opens ",
      directSignupOpenNow:
        "Participants are selected in the order of sign-ups. Sign-up closes ",
      lotterySignupStartsLater:
        "Participants are selected in a lottery. Lotter sign-up open ",
      lotterySignupOpen:
        "Participants are selected in a lottery. Lottery sign-up closes ",
      lotterySignupEnded: "Participants were selected in a lottery.",
      directSignupStarts: " Direct sign-up to remaining and cancelled spots: ",
    },
    signupCondition: {
      entryFeeInfo:
        "I understand that the workshop requires a material fee of {{ENTRY_FEE}}.",
      k16: "The game contains heavy themes and/or violence. By checking this box, I confirm that I am at least 16 years old during the game.",
      k18: "The game includes flirting between characters and possibly other more mature themes. To ensure a safer environment, we only allow adult players in the game. By checking this box, I confirm that I am at least 18 years old during the game.",
    },
  },
  attendeeType: {
    player: "player",
    participant: "participant",
  },
  attendeeTypePlural: {
    player: "players",
    participant: "participants",
  },
  attendeeTypePluralNominative: {
    player: "players",
    participant: "participants",
  },
  attendeeTypePartitive: {
    player: "players",
    participant: "participants",
  },
  programType: {
    all: "All program",
    tabletopRPG: "Tabletop RPG",
    larp: "LARP",
    tournament: "Tournament",
    workshop: "Workshop",
    experiencePoint: "Experience Point",
    other: "Other program",
    roundtableDiscussion: "Roundtable discussion",
    fleaMarket: "Flea market",
  },
  programTypeSelection: {
    all: "All program types",
    tabletopRPG: "Tabletop RPG",
    larp: "LARP",
    tournament: "Tournaments",
    workshop: "Workshops",
    experiencePoint: "Experience Point",
    other: "Other program",
    roundtableDiscussion: "Roundtable discussion",
    fleaMarket: "Flea market",
  },
  programTypeSingular: {
    all: "program item",
    tabletopRPG: "roleplaying game",
    larp: "LARP",
    tournament: "tournament",
    workshop: "workshop",
    experiencePoint: "game",
    other: "program item",
    roundtableDiscussion: "roundtable discussion",
    fleaMarket: "flea market time",
  },
  programTypePlural: {
    all: "program items",
    tabletopRPG: "roleplaying games",
    larp: "larps",
    tournament: "tournaments",
    workshop: "workshops",
    experiencePoint: "programs",
    other: "program items",
    roundtableDiscussion: "roundtable discussions",
    fleaMarket: "flea market times",
  },
  programTypeGenetive: {
    all: "Program item",
    tabletopRPG: "Roleplaying game",
    larp: "Larp",
    tournament: "Tournament",
    workshop: "Workshop",
    experiencePoint: "Program",
    other: "Program item",
    roundtableDiscussion: "Roundtable discussion",
    fleaMarket: "Flea market time",
  },
  programTypeElative: {
    all: "program item",
    tabletopRPG: "roleplaying game",
    larp: "larp",
    tournament: "tournament",
    workshop: "workshops",
    experiencePoint: "program",
    other: "program item",
    roundtableDiscussion: "roundtable discussion",
    fleaMarket: "flea market time",
  },
  programTypePartitivePlural: {
    all: "program items",
    tabletopRPG: "roleplaying games",
    larp: "larps",
    tournament: "tournaments",
    workshop: "workshops",
    experiencePoint: "games",
    other: "program items",
    roundtableDiscussion: "roundtable discussions",
    fleaMarket: "flea market times",
  },
  programTypeInessive: {
    all: "program item",
    tabletopRPG: "roleplaying game",
    larp: "larp",
    tournament: "tournament",
    workshop: "workshop",
    experiencePoint: "game",
    other: "program item",
    roundtableDiscussion: "roundtable discussion",
    fleaMarket: "flea market time",
  },
  programTypeIllative: {
    all: "program item",
    tabletopRPG: "roleplaying game",
    larp: "larp",
    tournament: "tournament",
    workshop: "workshop",
    experiencePoint: "program",
    other: "program item",
    roundtableDiscussion: "roundtable discussion",
    fleaMarket: "flea market time",
  },
  firstLogin: {
    serial: "Save this code for recovering your password:",
    info: "This message is shown only on your first login. You can also view your code on the Profile & Group page.",
  },
  myProgramView: {
    yourAnswer: "Your answer to the question",
    changePassword: "Change password",
    startingTimeChanged: "Starting time changed",
  },
  accessibility: {
    loudSounds: "Loud sounds",
    flashingLights: "Flashing or bright lights",
    strongSmells: "Strong smells",
    irritateSkin: "Materials or substances that irritate the skin",
    physicalContact: "Physical contact and/or low chances or personal space",
    lowLighting: "Darkness/low lighting",
    movingAround: "A lot of moving around without a chance for sitting down",
    video: "Video without subtitles for the hearing impaired",
    recording: "Recording without a text version for the hearing impaired",
    colourblind: "Materials used can cause problems for the colourblind",
    durationOver2h: "Duration over two hours withtout breaks",
    limitedMovingOpportunities:
      "There are limited opportunities to move around during the programme",
    longTexts: "Reading long texts independently",
    textWithNoRecordings:
      "Text essential to participation without a recoding or text read out loud",
    requiresDexterity: "Requires finger dexterity",
    requiresQuickReactions: "Requires ability to react quickly",
  },
  strategies: {
    direct: "Direct",
    lottery: "Lottery",
    "lottery+direct": "Lottery + Direct",
  },
  loginProvider: {
    local: "Local",
    kompassi: "Kompassi",
  },
  backendError: {
    apiError: "Error while calling {{method}} {{url}}, reason: {{errorReason}}",
    networkError: "Network error, connection to server failed",
    unauthorized: "Unauthorized",
    invalidRequest: "Invalid request",
    unknown: "Unknown error",
  },
  aboutView: {
    instructions: "Help",
    about: "About",
    faq: "FAQ",
    showMore: "Show more",
    showLess: "Show less",
  },
  profileView: {
    profileTab: "Profile",
    groupTab: "Group",
  },
  programItemPopularity: {
    low: "More {{ATTENDEE_TYPE}} needed",
    medium: "Enough {{ATTENDEE_TYPE}} to run",
    high: "More interested {{ATTENDEE_TYPE}} than seats",
  },
  selectedProgramType: "Program type",
  yes: "Yes",
  no: "No",
  signupQuestion: {
    privateQuestion: "Private question",
    questionType: "Type",
    addSignupTextField: "Add additional info field",
    inFinnish: "In Finnish",
    inEnglish: "In English",
  },
  signupQuestionType: {
    text: "Text",
    select: "Select",
  },
  private: "Private",
  privateOnlyVisibleToOrganizers: "Only visible to organizers",
  helperView: {
    signupQuestionAnswers: "Sign-up question answers",
    privateSignupMessagesInfo:
      "Private sign-up info for contacting tournament participants",
    noPrivateSignupMessages: "No answers",
    question: "Question",
  },
  loginView: {
    oldAccountsNotWorking:
      "Please note that accounts from older events are not in use anymore.",
    noAccountRegister: "No account? Create one here.",
    kompassiLoginHint: "Konsti login is done with Kompassi account",
    kompassiLogin: "Login or create account",
    createKompassiAccountHint:
      "Konsti login is not open yet but you can already create Kompassi account which is required for Konsti login.",
    createKompassiAccount: "Create a Kompassi account",
    chooseKonstiUsername: "Choose a Konsti username",
  },
  registrationView: {
    nickVisibleHintText: "Your username will be visible to other Konsti users.",
    alreadyHaveAccountLogin: "Do you already have an account? Log in here.",
  },
  admissionView: {
    noAdmission: "You have not signed up for this program item.",
    admission: "You have signed up as user ",
  },
  iconAltText: {
    resetInput: "Reset input",
    openAccordion: "Open accordion menu",
    closeAccordion: "Close accordion menu",
    closeError: "Close error",
    closeNotification: "Close notification",
    aboutKonsti: "About Konsti",
    openNavigation: "Open navigation menu",
    closeNavigation: "Close navigation menu",
    addFavorite: "Add favorite",
    deleteFavorite: "Delete favorite",
    openChangePassword: "Open change password",
    closeChangePassword: "Close change password",
    openAttendeeList: "Open {{ATTENDEE_TYPE}} list",
    closeAttendeeList: "Close {{ATTENDEE_TYPE}} list",
    showPassword: "Show password",
    hidePassword: "Hide password",
    newNotifications: "New notifications",
  },
  admin: {
    sentryTesting: "Sentry testing",
    sentryClientTest: "Client test",
    sentryBackendTest: "Backend test",
    signupStrategy: "Signup strategy",
    loginProvider: "Login provider",
  },
} as const;
